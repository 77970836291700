import { BridgeComponent } from "@hotwired/hotwire-native-bridge";
import { BridgeElement } from "@hotwired/hotwire-native-bridge";

export default class extends BridgeComponent {
  static component = "menu";
  static targets = ["title", "item"];

  show(event) {
    if (this.enabled) {
      event.stopImmediatePropagation();
      this.notifyBridgeToDisplayMenu(event);
    }
  }

  notifyBridgeToDisplayMenu() {
    const title = this.hasTitleTarget
      ? new BridgeElement(this.titleTarget).title
      : null;

    const items = this.itemTargets.map((item, index) => {
      const bridgeItem = new BridgeElement(item);

      return {
        title: bridgeItem.title ?? "",
        destructive: item.dataset.turboMethod === "delete",
        index,
      };
    });

    this.send("display", { title, items }, (message) => {
      const selectedIndex = message.data.selectedIndex;
      const selectedItem = new BridgeElement(this.itemTargets[selectedIndex]);

      selectedItem.click();
    });
  }
}
