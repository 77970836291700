import { Controller } from "@hotwired/stimulus";
import { Turbo } from "@hotwired/turbo-rails";

export default class extends Controller {
  select(e) {
    const { crew } = document.body.dataset;

    Turbo.visit(`/${crew}/events/${e.detail.value}`);
  }
}
