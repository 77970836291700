import { BridgeComponent } from "@hotwired/hotwire-native-bridge";
import { BridgeElement } from "@hotwired/hotwire-native-bridge";

export default class extends BridgeComponent {
  static component = "form";
  static targets = ["submit"];

  connect() {
    super.connect();
    this.notifyBridgeOfConnect();
  }

  notifyBridgeOfConnect() {
    const submitButton = new BridgeElement(this.submitTarget);
    const submitTitle = submitButton.title;
    const disabled = this.submitTarget.disabled;

    this.send("connect", { submitTitle, disabled }, () => {
      this.submitTarget.click();
    });
  }

  submitStart() {
    this.submitTarget.disabled = true;
    this.send("submitDisabled");
  }

  submitEnd() {
    this.submitTarget.disabled = false;
    this.send("submitEnabled");
  }
}
